import Logo from "~image/logo/logo.svg"

import Signin from "~image/account/sign-in.jpg"
import Signup from "~image/account/sign-up.jpg"
import Reset from "~image/account/reset-pass-img.jpg"

import CertIcon from "~image/integration/cert.png"
import RegisterIcon from "~image/integration/register.png"
import ReceiveIcon from "~image/integration/receive.png"
import CodeIcon from "~image/experience/code.png"
import StoreIcon from "~image/experience/store.png"
import ScannerIcon from "~image/experience/scanner.png"
import POSIcon from "~image/experience/pos.png"
import TicketIcon from "~image/experience/ticket.png"
import PayIcon from "~image/experience/pay.png"
import DiagramIcon from "~image/process/diagrama.svg"
import DiagramPngIcon from "~image/process/diagrama.png"

import HomeImage from "~image/sections/home.jpg"
import OverlayImage from "~image/sections/overlay.png"
import DashboardImage from "~image/sections/dashboard.png"
import CelEnefevoImage from "~image/sections/celular-enefevo.png"
import GreenShapeImage from "~image/sections/green-shape.png"
import RedShapeImage from "~image/sections/red-shape.png"

const Images = {
  Logo,

  Account: {
    Signin,
    Signup,
    Reset,
  },

  Sections: {
    HomeImage,
    OverlayImage,
    DashboardImage,
    CelEnefevoImage,
    GreenShapeImage,
    RedShapeImage,
  },

  Integragion: {
    CertIcon,
    RegisterIcon,
    ReceiveIcon,
  },

  Experience: {
    CodeIcon,
    StoreIcon,
    ScannerIcon,
    POSIcon,
    TicketIcon,
    PayIcon,
  },

  Process: {
    DiagramIcon,
    DiagramPngIcon,
  },
}

export default Images
